.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.center {
  left: 50%;
  margin-left: -4em;
  position: absolute;
  top: 50%;
}

.main-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.container {
  background-position: fixed;
  background-repeat: no-repeat;
  background-size: auto;
  -webkit-background-size: auto;
  -moz-background-size: auto;
  -o-background-size: auto;
  height: 100%;
  width: 100%;
  background: rgba(13, 26, 41, 0.7);
}

.card {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  text-align: center;
  padding: 55px 32px;
  width: 370px;
  transform: translate(-50%, -50%);
  background: rgba(135, 131, 145, 0.04);
  box-shadow: -1px 4px 28px 0px rgba(0, 0, 0, 0.75);
  border-radius: 20px;
}

.ant-input {
  background: transparent;
  background-color: #28314200;
}

.ant-input-affix-wrapper {
  background: transparent;
  border-color: #ffffff91;
  box-shadow: none;
  color: white;
}

.text-box1 {
  background-color: transparent !important;
}
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background-color: #fff0;
  border-color: #ff6b727e;
  box-shadow: none;
  color: white;
}

.anticon .anticon {
  border-color: white;
}

.ant-btn {
  font-size: 15px;
}

/* 
style botton del upload image */
.file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.file-input label {
  display: block;
  position: relative;
  width: 200px;
  height: 50px;
  border-radius: 15px;
  background: #3e79f7;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease-out;
  margin-left: 10px;
}

.file-name {
  position: absolute;
  bottom: -35px;
  left: 10px;
  font-size: 0.85rem;
  color: #555;
}

input:hover + label,
input:focus + label {
  transform: scale(1.02);
}

/* Adding an outline to the label on focus */
input:focus + label {
  outline: 1px solid #000;
  outline: -webkit-focus-ring-color auto 2px;
}
.image-Frame-img {
  margin-bottom: 15px;
  border-radius: 15px;
}

/* button style de editar perfil */
.bottonStyle {
  height: 40px;
  width: 81.8833px;
}

.spin {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

/*estilos utilizados  para el dashboard principal de vacaciones */
.cardPrincipal {
  box-shadow: 0 6px 10px rgba(26, 60, 250, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 14px 80px 18px 36px;
}

.cardPrincipal:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

/* estilo de ver perfil */
.ant-input-disabled,
.ant-input[disabled] {
  color: #fff;
  background-color: #3a558f00;
}

.ant-input::placeholder {
  color: rgb(255, 255, 255);
}

/* Estilos modal */
.modalStyle .ant-modal-header {
  border-radius: 20px 20px 0 0;
}

.newStyle {
  border-radius: 20px;
}

.ant-modal-content {
  border-radius: 20px;
}

.modalStyle2 {
  border-radius: 20px;
}

.file-upload {
  display: block;
  position: relative;
  width: 200px;
  height: 50px;
  border-radius: 15px;
  background: #3e79f7;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease-out;
  margin-left: 10px;
}

.label-upload {
  cursor: pointer;
}

.ant-upload-list-item {
  height: fit-content;
  width: fit-content;
}

.fileright  {
  display: flex;
  justify-content: flex-end;
}

.webviewer{
  height: 70vh;
  width: 70vh;
}
.modalStyle {
  display: flex;
}

.inputdays{
  padding-left: 4rem;
  border: px solid;
  background-color:aqua;
}

.fade-in-text{
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}


.transparent-background {
  background: rgba(0, 0, 0, 0);
  border: 2px dashed #91d5ff; /* Estilo del borde predeterminado */
  border-radius: 8px;
  padding: 20px;
  transition: border-color 0.3s; /* Transición suave para el cambio de color */
}

.transparent-background:hover {
  border-color: blue; /* Cambia el color del borde a azul en hover */
}

